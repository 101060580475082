import React     from 'react';
import PropTypes from 'prop-types';

class Sorter extends React.Component {

	static findSelectedIndex(items) {
		for (let i = 0; i < items.length; ++i) {
			if (items[i]?.selected) {
				return i;
			}
		}

		return 0;
	}

	static getSelectedIndex(state, props) {
		return props.useSelectedIndexFromProps ? this.findSelectedIndex(props.items) : state.internalSelectedIndex;
	}

	constructor(props) {
		super(props);

		this.state = {
			selectionVisible:      false,
			internalSelectedIndex: Sorter.findSelectedIndex(this.props.items),
		};

		this.handleSectionClick            = this.handleSectionClick.bind(this);
		this.handleItemClick               = this.handleItemClick.bind(this);
		this.handleMouseLeaveItemSelection = this.handleMouseLeaveItemSelection.bind(this);
	}

	handleSectionClick() {
		this.setState(state => ({
			selectionVisible: !state.selectionVisible,
		}));
	}

	handleItemClick(event, index, item) {
		this.setState({
			selectionVisible:      false,
			internalSelectedIndex: index,
		});

		if (typeof this.props.onItemClick === 'function') {
			event.preventDefault();
			event.stopPropagation();

			this.props.onItemClick(item);
		}
	}

	handleMouseLeaveItemSelection() {
		this.setState({
			selectionVisible: false,
		});
	}

	render() {
		const selectedIndex = Sorter.getSelectedIndex(this.state, this.props);

		const linkDom = this.props.items.map((item, key) => {
			const linkCss     = 'sim-sorter__options__item ' + (selectedIndex === key ? ' is-active' : '');
			const handleClick = (e) => this.handleItemClick(e, key, item);
			return <a href={item?.url ? item.url : ''} onClick={handleClick} className={linkCss} key={key}>
				{item?.text}
			</a>;
		});
		let className = 'h-pos-relative sim-sorter'
			+ (this.props.className ? ' ' + this.props.className : '')
			+ (this.state.selectionVisible ? ' is-open' : '')
			+ (this.props.hasLeftElement ? ' -haselementleft' : '')
			+ (this.props.hasRightElement ? ' -haselementright' : '')
			+ (this.props.useNewDesign ? " sim-sorter--new" : "");

		if (this.props.isMobile) {
			className = "";

		}

		if (this.props.isDisabled) {
			return <div className={className + " h-disabled"}>
				{this.props.isMobile && <i className={"icon -icon-sort-line " + (this.state.selectionVisible ? "h-color-highlight" : "")}></i>}
			</div>;
		}

		return (
			<div className={className}
			     onMouseLeave={this.handleMouseLeaveItemSelection}
			     onClick={this.handleSectionClick}
			>
				{/*label*/}
				{this.props.isMobile && <i className={"icon -icon-sort-line " + (this.state.selectionVisible ? "h-color-highlight" : "")}></i>}

				<span className="sim-sorter__value-block">

					{!this.props.isMobile && <span className="sim-sorter__current-value">{this.props.items[selectedIndex]?.text}</span>}
					{!this.props.isMobile && this.props.useNewDesign && <i className={'sim-sorter__arrow icon -icon-single-arrow-down-line' + (this.state.selectionVisible ? ' sim-sorter__arrow--turn' : '')}></i>}
					<div className={'sim-sorter__options ' + ((this.state.selectionVisible) ? 'h-disp-block ' : 'h-disp-hidden ') + (this.props.isMobile ? 'h-text-align-right' : '') + (this.props.additionalClasses ? this.props.additionalClasses : '')}>
						{linkDom}
					</div>
				</span>
			</div>
		);
	}
}

Sorter.propTypes = {
	onItemClick:               PropTypes.func,
	items:                     PropTypes.array,
	className:                 PropTypes.string,
	label:                     PropTypes.string,
	hasLeftElement:            PropTypes.bool,
	hasRightElement:           PropTypes.bool,
	useSelectedIndexFromProps: PropTypes.bool,
	isMobile:                  PropTypes.bool,
	isDisabled:                PropTypes.bool,
	additionalClasses:         PropTypes.string,
	useNewDesign:              PropTypes.bool,
};

Sorter.defaultProps = {
	items:                        [],
	className:                    '',
	hasLeftElement:               false,
	hasRightElement:              false,
	updateSelectedIndexWithProps: false,
	isMobile:                     false,
	isDisabled:                   false,
	useNewDesign:                 false,
};

export default Sorter;
